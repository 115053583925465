@font-face {
  font-family: 'bal_icons';
  src: url('../fonts/bal-icons/bal_icons.ttf?u6ximz') format('truetype'),
  url('../fonts/bal-icons/bal_icons.woff?u6ximz') format('woff'),
  url('../fonts/bal-icons/bal_icons.svg?u6ximz#bal_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'bal_icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-info-bubble:before {
  content: "\e92a";
}

.icon-email:before {
  content: "\e926";
}

.icon-Filter-on:before {
  content: "\e927";
}

.icon-list-summary:before {
  content: "\e928";
}

.icon-phone:before {
  content: "\e929";
}

.icon-arrow-subcontainer:before {
  content: "\e900";
  transition: all 0.2s ease;
}

.icon-arrow-subcontainer--up:before {
  content: "\e900";
  display: inline-block;
  transform-origin: center;
  transform: rotate(-90deg);
}

.icon-arrow-subcontainer--right:before {
  content: "\e900";
  display: inline-block;
}

.icon-arrow-subcontainer--down:before {
  content: "\e900";
  display: inline-block;
  transform: rotate(90deg);
}

.icon-arrow-subcontainer--left:before {
  content: "\e900";
  display: inline-block;
  transform: rotate(180deg);
}

.icon-arrow--right:before {
  content: "\e900";
  display: inline-block;
  transform-origin: center;
}

.icon-arrow-up:before,
.icon-arrow--asc:before {
  content: "\e901";
}

.icon-arrow-down:before,
.icon-arrow--desc:before {
  content: "\e901";
  display: inline-block;
  transform: rotate(180deg)
}


.icon-arrow-left::before {
  content: "\e901";
  transform: rotate(-90deg);
  display: inline-block;
}

.icon-arrow-right::before {
  content: "\e901";
  transform: rotate(90deg);
  display: inline-block;
}

.icon-back-arrow-secondary:before {
  content: "\e902";
}

.icon-carrot:before {
  content: "\e903";
  display: inline-block;
  transform: rotate(90deg);
}

.icon-carrot-closed:before {
  content: "\e903";
}

.icon-carrot-up:before {
  content: "\e903";
  display: inline-block;
  transform: rotate(-90deg);
}

.icon-carrot-right:before {
  content: "\e903";
  display: inline-block;
  transform: rotate(0);
}

.icon-carrot-down:before {
  content: "\e903";
  display: inline-block;
  transform: rotate(90deg);
}

.icon-carrot-left:before {
  content: "\e903";
  display: inline-block;
  transform: rotate(180deg);
}

.icon-clock-large:before {
  content: "\e904";
}

.icon-delete:before {
  content: "\e905";
}

.icon-districts:before {
  content: "\e907";
}

.icon-download-default:before {
  content: "\e908";
}

.icon-drop-down-arrows:before {
  content: "\e909";
}

.icon-duplicate:before {
  content: "\e90a";
}

.icon-exclude-location .path1:before {
  content: "\e90b";
  color: rgb(0, 0, 0);
}

.icon-exclude-location .path2:before {
  content: "\e90c";
  margin-left: -0.619140625em;
  color: rgb(255, 255, 255);
}

.icon-filter:before {
  content: "\e90d";
}

.icon-help:before {
  content: "\e90f";
}

.icon-in:before {
  content: "\e910";
}

.icon-include-location-check:before {
  content: "\e911";
}

.icon-checked:before {
  content: "\e912";
}

.icon-people-dark:before {
  content: "\e92b";
}

.icon-people-dark:before {
  content: "\e92b";
}

.icon-wrench:before {
  content: "\e92c";
}

//-----------------------
// Targeting types icons;
.targeting-type__icon--1:before {
  content: "\e913";
}

.targeting-type__icon--2:before {
  content: "\e90e";
}

.targeting-type__icon--3:before {
  content: "\e914";
}

.targeting-type__icon--4:before {
  content: "\e906";
}

.targeting-type__icon--5:before,
.targeting-type__icon--9:before,
.targeting-type__icon--10:before {
  content: url('./../img/PIDs.svg');
}

// TODO: add real icon, check id when BE will be ready
.targeting-type__icon--5:before {
  content: "Abc";
  font-family: Courier;
  color: #0078A7;
  font-size: 1rem;
}

.targeting-type__icon--6, .targeting-type__icon--7 {
  &:before {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.targeting-type__icon--6:before {
  content: '';
  background-image: url('./../img/grand.svg');
}

.targeting-type__icon--7:before {
  content: '';
  background-image: url('./../img/rfp.svg');
}

// map marker
.icon-include:before {
  content: url('./../img/include-location.svg');
}

.icon-exclude:before {
  content: url('./../img/exclude-location.svg');
}

//-----------------------

.icon-institutions:before {
  content: "\e913";
}

.icon-geography:before {
  content: "\e90e";
}

.icon-job_titles:before {
  content: "\e914";
}

.icon-demographics:before {
  content: "\e906";
}

// -----------------------
// List types icons
.add-list__type--1:before {
  content: "\e915";
}

.add-list__type--2:before {
  content: "\e917";
}

.add-list__type--3:before {
  content: "\e916";
}

// -----------------------

.icon-list_type-email_only:before {
  content: "\e915";
}

.icon-list_type-email-mailing:before {
  content: "\e916";
}

.icon-list_type-mailing_only:before {
  content: "\e917";
}

.icon-menu:before {
  content: "\e918";
}

.icon-page-close:before {
  content: "\e919";
}

.icon-refresh:before {
  content: "\e91a";
}

.icon-search:before {
  content: "\e91b";
}

.icon-sync:before {
  content: "\e91c";
}

.icon-star:before {
  content: "\e91d";
}

.icon-startburst:before {
  content: "\e91e";
}

.icon-states:before {
  content: "\e91f";
}

.icon-submenu-rollover:before {
  content: "\e920";
}

.icon-target:before {
  content: "\e921";
}

.icon-targeting:before {
  content: "\e922";
}

.icon-tooltip:before {
  content: "\e923";
}

.icon-x-medium:before {
  content: "\e924";
}

.icon-x-small:before {
  content: "\e925";
}

.icon-draggable:before {
  content: "\283F";
}

.icon-list {
  &__delivery-type-1 {
    background-image: url(./../img/download-black.svg);
    background-repeat: no-repeat;
  }

  &__delivery-type-3 {
    background-image: url(./../img/custom-black.svg);
    background-repeat: no-repeat;
  }

  &__delivery-type-4 {
    background-image: url(./../img/cdl-black.svg);
    background-repeat: no-repeat;
  }

  &__delivery-type-5 {
    background-image: url(./../img/email_icon.svg);
    background-repeat: no-repeat;
  }

  &__type-1 {
    background-image: url(./../img/list_type-email_only-black.svg);
    background-repeat: no-repeat;
  }

  &__type-2 {
    background-image: url(./../img/list_type-mailing_only-black.svg);
    background-repeat: no-repeat;
  }

  &__type-3 {
    background-image: url(../img/all-list-black.svg);
    background-repeat: no-repeat;
  }

  &__target-type-1 {
    background-image: url(./../img/people-cyan-black.svg);
    background-repeat: no-repeat;
  }

  &__target-type-2 {
    background-image: url(./../img/institutions-black.svg);
    background-repeat: no-repeat;
  }

  &__target-type-3 {
    background-image: url(./../img/home-black.svg);
    background-repeat: no-repeat;
  }

  &__target-type-4 {
    background-image: url(./../img/inst-pers-black.svg);
    background-repeat: no-repeat;
  }
}

.icon-success:before {
  content: url(./../img/success-check.svg);
}

.icon-cc-vs::before {
  content: url(./../img/cc-visa-logo.svg);
}

.icon-cc-ds::before {
  content: url(./../img/cc-discover-logo.svg);
}

.icon-cc-mc::before {
  content: url(./../img/cc-masterc-logo.svg);
}

.icon-cc-amex::before {
  content: url(./../img/cc-amex-logo.svg);
}

.icon-cc-dn::before {
  // TODO need add icon for diners card
  //content: url(./../img/cc-diners-logo.svg);
}

.icon-view::before {
  content: url('./../img/view.svg');
}

.icon-list::before {
  content: url('./../img/list.svg');
}

.icon-quick-search-small::before {
  position: absolute;
  display: block;
  width: 40px;
  height: 40px;
  content: '';
  background: url('./../img/quick-search.svg') no-repeat 50% 50%;
  background-size: 18px;
}

.icon-quick-search--big::before {
  display: block;
  width: 30px;
  height: 30px;
  content: '';
  background: url('./../img/quick-search.svg') no-repeat 50% 50%;
  background-size: 28px;
}

.icon-view-quick-search--blue::before {
  display: block;
  width: 68px;
  height: 68px;
  content: '';
  background: url('./../img/view.svg') no-repeat 50% 50%;
  background-size: 68px;
}

.icon-view-quick-search::before {
  display: block;
  width: 68px;
  height: 68px;
  content: '';
  background: url('./../img/advanced-quick-search-gray.svg') no-repeat 50% 50%;
  background-size: 68px;
}

.icon-list-quick-search::before {
  display: block;
  width: 30px;
  height: 32px;
  content: '';
  background: url('./../img/list.svg') no-repeat 50% 50%;
}

.icon-create-list-big::before {
  display: block;
  width: 194px;
  height: 174px;
  content: '';
  background: url('./../img/create-list-big.png') no-repeat 50% 50%;
}

.icon-plus {
  display: inline-block;
  position: relative;
  height: 40px;
  width: 40px;
  border-radius: 20px;

  &::before, &::after {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    background-color: #00B2A9;
  }

  &::before {
    content: '';
    height: 2px;
    width: 20px;
  }

  &::after {
    content: '';
    height: 20px;
    width: 2px;
  }

  &.modal {
    &::before, &::after {
      background-color: #0078A7;
    }

    &::before {
      width: 28px;
    }

    &::after {
      height: 28px;
    }
  }
}

// icon plus in circle
.icon-circle-plus {
  display: inline-block;
  position: relative;
  width: 15px;
  height: 15px;
  border: 1px solid #0078A7;
  border-radius: 100%;
  vertical-align: middle;
  margin: 0 4px;
  background: transparent;
}

.icon-circle-plus:before,
.icon-circle-plus:after {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.icon-circle-plus:before,
.icon-circle-plus:after {
  background: #0078A7;
}

.icon-circle-plus:before {
  content: '';
  width: 1px;
  margin: 3px auto;
}

.icon-circle-plus:after {
  content: '';
  margin: auto 3px;
  height: 1px;
}

.icon-print::before {
  content: url('../img/print.svg');
}

.icon-home::before {
  display: block;
  width: 28px;
  height: 22px;
  content: '';
  background: url('../img/home-mobile.svg');
}

.icon-refresh--circle::after {
  content: url('../img/resend.svg');
}

.icon-success-small::after {
  content: url("../img/success-small.svg");
}

.icon-glasses {
  display: inline-block;
  vertical-align: middle; // TODO remove when normal icon will be ready;
  height: 15px;
  width: 35px;
  background: url('../img/show_email.png');
  background-size: cover;
}

.icon-people:before {
  content: url('./../img/people-cyan.svg');
}

.icon-quick-search-gray::before {
  display: block;
  width: 50px;
  height: 50px;
  content: '';
  background: url('./../img/search-gray.svg') no-repeat 50% 50%;
}

.link-out::before {
  content: url(./../img/link-out.svg);
}

.link-out-across::before {
  content: url(./../img/link-out-2.svg);
}

.link-out-across-grey::before {
  content: url(./../img/link-out-across-grey.svg);
}

.arrow-back-mobile::before {
  display: block;
  width: 21px;
  height: 22px;
  content: '';
  background: url('../img/mobile-back.svg') no-repeat 50% 50%;
}

.icon-radar::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  background: url('../img/radar.svg') no-repeat 50% 50%;
}

.icon-home-mobile::before {
  display: block;
  width: 28px;
  height: 22px;
  content: '';
  background: url('../img/home-mobile.svg') no-repeat 50% 50%;
}

.icon-menu-mobile::before {
  display: block;
  width: 16px;
  height: 10px;
  content: '';
  background: url('../img/main-menu.svg') no-repeat 50% 50%;
}

.quick-search-btn-mobile::before {
  display: block;
  width: 18px;
  height: 18px;
  content: '';
  background: url('../img/quick-search-mobile.svg') no-repeat 50% 50%;
}

.icon-globe:before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  background: url('../img/globe.png') no-repeat 50% 50%;
}

.icon-address-physical::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  background: url('../img/address-physical.png') no-repeat 50% 50%;
}

.icon-address-mailing-white::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  background: url('../img/address-mailing-white.svg') no-repeat 50% 50%;
}

.icon-address-physical-gray::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  background: url("../img/address-gray.svg") no-repeat 50% 50%;
}

.icon-district::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  background: url('../img/district.png') no-repeat 50% 50%;
}

.icon-district-gray::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  background: url("../img/district-gray.svg") no-repeat 50% 50%;
}

.icon-fax::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  background: url('../img/fax.png') no-repeat 50% 50%;
}

.icon-call::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  background: url('../img/call-new.svg') no-repeat 50% 50%;
}

.icon-back-mobile::before {
  display: inline-block;
  width: 28px;
  height: 22px;
  content: '';
  background: url('../img/back-mobile.svg') no-repeat 50% 50%;
}

.icon-arrow-back::before {
  display: inline-block;
  height: 21px;
  width: 12px;
  vertical-align: middle;
  content: '';
  background: url('../img/back-mobile.svg') no-repeat 50% 50%;
}

.icon-call-gray::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  background: url('../img/call-new-gray.svg') no-repeat 50% 50%;
}

.icon-contact-white::before {
  display: inline-block;
  width: 20px;
  height: 20px;
  content: '';
  background: url('../img/contact-white.svg') no-repeat 50% 50%;
}

.icon-sign-up-check::before {
  display: block;
  width: 30px;
  height: 30px;
  content: '';
  background: url('./../img/signup-check.svg') no-repeat 50% 50%;
}

.icon-sign-up-success::before {
  display: block;
  width: 30px;
  height: 30px;
  content: '';
  background: url('./../img/signup-success.svg') no-repeat 50% 50%;
}

.icon-sign-up-laptop::before {
  display: block;
  width: 500px;
  height: 293px;
  content: '';
  background: url('./../img/signup-laptop.png') no-repeat 50% 50%;
}

.icon-edit-pencil::before {
  display: block;
  width: 18px;
  height: 18px;
  content: '';
  background: url('./../img/edit-penсil.svg') no-repeat 50% 50%;
}

.icon-hierarchies {
  display: block;
  width: 28px;
  height: 28px;
  content: '';
  background: url(./../img/hierarchies.svg) no-repeat;
  background-size: 100%;
}

.icon-exclamation-point::before {
  content: url('../img/exclamation-point.svg');
}

.tag-details-icon::before {
  content: "\e910";

  font-family: 'bal_icons';
  font-size: 11px;
}

.icon-premium-tag {
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url(./../img/premium-tag.svg) no-repeat 50% 50%;
  background-size: contain;
  vertical-align: middle;
}

.icon-download--down {
  display: inline-block;
  width: 24px;
  height: 24px;
  background: url('./../img/download-gray.svg') no-repeat 50% 50%;
}

.icon-grid-view {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url('./../img/grid-view.svg') no-repeat 50% 50%;
}

.icon-list-view {
  display: inline-block;
  width: 12px;
  height: 12px;
  background: url('./../img/list-view.png') no-repeat 50% 50%;
}
